import React, {useEffect, useRef, useState} from "react";
import {Contract, ethers} from "ethers";

import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en);

const abi = [
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "challenger",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "chainLength",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "oldHash",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "bytes32",
                "name": "newHash",
                "type": "bytes32"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "nextMint",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "string",
                "name": "tag",
                "type": "string"
            }
        ],
        "name": "ChainProgress",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CFELDE",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "CHAIN_LENGTH_TARGET",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "MAX_MINT",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "STALLED_DURATION",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "chainLength",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lastBlockNumber",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lastChallenger",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lastHash",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "seed",
                "type": "uint256"
            },
            {
                "internalType": "string",
                "name": "tag",
                "type": "string"
            }
        ],
        "name": "mint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "mintValue",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "progress",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "nextMint",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "stalledTimestamp",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

function getProvider(url) {
    const provider = new ethers.providers.JsonRpcProvider(url);
    provider.pollingInterval = 60000; // 60 seconds

    return provider;
}

function Activity(props) {
    const {
        contractAddress,
        ticker,
        name,
        networkName,
        contractExplorerPrefix,
        txExplorerPrefix,
        providerUrl
    } = props.token;

    const provider = getProvider(providerUrl);

    const [haveListener, setHaveListener] = useState(false);
    const [, setUpdateTimestamp] = useState(0);
    const activity = useRef([]);

    useEffect(() => {
        document.body.className = "page2"
    });

    // TODO Deregister?
    useEffect(() => {
        async function chainProgress(challenger, chainLength, oldHash, newHash, nextMint, tag, extra) {
            const txHash = extra.transactionHash;
            const blockNumber = extra.blockNumber;
            const blockHash = extra.blockHash;
            const blockTime = (await provider.getBlock(blockNumber)).timestamp;

            const uniqueByBlockNumber = (arr) => {
                const blocks = new Set();
                return arr.filter(e => !blocks.has(e.blockNumber) && blocks.add(e.blockNumber));
            }

            const updatedActivity = uniqueByBlockNumber([{
                challenger: challenger,
                chainLength: chainLength,
                newHash: newHash,
                blockNumber: blockNumber,
                blockHash: blockHash,
                blockTime: blockTime,
                nextMint: nextMint,
                tag: tag,
                txHash: txHash
            }].concat(activity.current))
                .sort((a, b) => {return b.blockNumber - a.blockNumber})
                .slice(0, 5);

            activity.current = updatedActivity;
            setUpdateTimestamp(blockTime);
        }

        if (!haveListener) {
            const contract = new Contract(contractAddress, abi, provider);
            provider.getBlockNumber().then((blockNumber) => {
                const filter = contract.filters.ChainProgress();
                contract.queryFilter(filter, blockNumber - 1000).then((r) => {
                    r.forEach((e) => {
                        chainProgress(
                            e.args.challenger,
                            e.args.chainLength,
                            e.args.oldHash,
                            e.args.newHash,
                            e.args.nextMint,
                            e.args.tag,
                            e
                        );
                    });
                });
            })
            contract.on("ChainProgress", chainProgress);
            setHaveListener(true);
        }
    }, [haveListener, activity])

    return (
        <div>
            <div className={"header"}>
                <div className={"menuBar"}>
                    <a href={"/about/"} className={"menuLink"}>NuPoW</a>
                    <a href={"/crystals/"} className={"menuLink"}>Crystals</a>
                </div>
            </div>
            <div className={"banner"}>
                <img src="/img/2.png"/>
            </div>
            <div className={"content"}>
                Contract address: <a href={contractExplorerPrefix + contractAddress}>{contractAddress}</a>
                <br/>
                Name: {name} [{ticker}]
                <br/>
                Network: {networkName}
            </div>
            {(() => {
                return activity.current.map((a) => {
                    return (
                        <div key={a.txHash} className={"content"}>
                            {a.challenger} minted {ethers.utils.formatUnits(a.nextMint, 18)} {ticker} <ReactTimeAgo date={new Date(a.blockTime * 1000)} locale="en-US" /> on transaction hash <a href={txExplorerPrefix + a.txHash}>{a.txHash}</a> with chain length {a.chainLength.toString()} and tag: {a.tag}
                        </div>
                    )
                })
            })()}
            <div className={"footer"}>
                Copyright 2022 <a href={"https://cfelde.com"}>cfelde</a>, all rights reserved.
                You are responsible for your own actions.
            </div>
        </div>
    );
}

export default Activity;
