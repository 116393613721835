import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from "react-router-dom";

import About from "./About";
import Crystals from "./Crystals";
import Activity from "./Activity";

const npKovan1 = {
    contractAddress: "0x5754046EaD16f75CfB2Cc6FCc91C6Ace118d6f71",
    ticker: "NPK1",
    name: "NuPoW Kovan 1",
    networkName: "Kovan",
    contractExplorerPrefix: "https://kovan.etherscan.io/token/",
    txExplorerPrefix: "https://kovan.etherscan.io/tx/",
    providerUrl: "https://kovan.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npKovan2 = {
    contractAddress: "0xBC0D8283aF1CadEDbbe6445E292811E7cEb58703",
    ticker: "NPK2",
    name: "NuPoW Kovan 2",
    networkName: "Kovan",
    contractExplorerPrefix: "https://kovan.etherscan.io/token/",
    txExplorerPrefix: "https://kovan.etherscan.io/tx/",
    providerUrl: "https://kovan.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npRinkeby1 = {
    contractAddress: "0xDD917AfF3cB7Df442e45A93E6B33B02caDe150Eb",
    ticker: "NPR1",
    name: "NuPoW Rinkeby 1",
    networkName: "Rinkeby",
    contractExplorerPrefix: "https://rinkeby.etherscan.io/token/",
    txExplorerPrefix: "https://rinkeby.etherscan.io/tx/",
    providerUrl: "https://rinkeby.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npRinkeby2 = {
    contractAddress: "0x5754046EaD16f75CfB2Cc6FCc91C6Ace118d6f71",
    ticker: "NPR2",
    name: "NuPoW Rinkeby 2",
    networkName: "Rinkeby",
    contractExplorerPrefix: "https://rinkeby.etherscan.io/token/",
    txExplorerPrefix: "https://rinkeby.etherscan.io/tx/",
    providerUrl: "https://rinkeby.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npGoerli1 = {
    contractAddress: "0x1B471d9440FE8e256B8b3568d55e84AA2811b9fc",
    ticker: "NPG1",
    name: "NuPoW Goerli 1",
    networkName: "Goerli",
    contractExplorerPrefix: "https://goerli.etherscan.io/token/",
    txExplorerPrefix: "https://goerli.etherscan.io/tx/",
    providerUrl: "https://goerli.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npGoerli2 = {
    contractAddress: "0x714B3d65824CdD9E7Fe9E9cffE427E4Bb821cC7d",
    ticker: "NPG2",
    name: "NuPoW Goerli 2",
    networkName: "Goerli",
    contractExplorerPrefix: "https://goerli.etherscan.io/token/",
    txExplorerPrefix: "https://goerli.etherscan.io/tx/",
    providerUrl: "https://goerli.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npRopsten1 = {
    contractAddress: "0xDD917AfF3cB7Df442e45A93E6B33B02caDe150Eb",
    ticker: "NPR1",
    name: "NuPoW Ropsten 1",
    networkName: "Ropsten",
    contractExplorerPrefix: "https://ropsten.etherscan.io/token/",
    txExplorerPrefix: "https://ropsten.etherscan.io/tx/",
    providerUrl: "https://ropsten.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npRopsten2 = {
    contractAddress: "0x5754046EaD16f75CfB2Cc6FCc91C6Ace118d6f71",
    ticker: "NPR2",
    name: "NuPoW Ropsten 2",
    networkName: "Ropsten",
    contractExplorerPrefix: "https://ropsten.etherscan.io/token/",
    txExplorerPrefix: "https://ropsten.etherscan.io/tx/",
    providerUrl: "https://ropsten.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npArbitrumTest1 = {
    contractAddress: "0x450bBb17f7FF7ffDea973830d93a5707b6763b51",
    ticker: "NPAT1",
    name: "NuPoW ArbTest 1",
    networkName: "Arbitrum Rinkeby",
    contractExplorerPrefix: "https://testnet.arbiscan.io/token/",
    txExplorerPrefix: "https://testnet.arbiscan.io/tx/",
    providerUrl: "https://rinkeby.arbitrum.io/rpc"
}

const npArbitrumTest2 = {
    contractAddress: "0x1dF67daF0c7DEE6dA4BBd90AB8Fa934a291886Eb",
    ticker: "NPAT2",
    name: "NuPoW ArbTest 2",
    networkName: "Arbitrum Rinkeby",
    contractExplorerPrefix: "https://testnet.arbiscan.io/token/",
    txExplorerPrefix: "https://testnet.arbiscan.io/tx/",
    providerUrl: "https://rinkeby.arbitrum.io/rpc"
}

const npOptimismKovan1 = {
    contractAddress: "0xeF7697768fdc972e2B2c1bC01656bacE5d158062",
    ticker: "NPOPT1",
    name: "NuPoW OpTest 1",
    networkName: "Optimism Kovan",
    contractExplorerPrefix: "https://kovan-optimistic.etherscan.io/token/",
    txExplorerPrefix: "https://kovan-optimistic.etherscan.io/tx/",
    providerUrl: "https://kovan.optimism.io"
}

const npOptimismKovan2 = {
    contractAddress: "0xF9030587Dba580022c72b2F6868ac8a4E0404471",
    ticker: "NPOPT2",
    name: "NuPoW OpTest 2",
    networkName: "Optimism Kovan",
    contractExplorerPrefix: "https://kovan-optimistic.etherscan.io/token/",
    txExplorerPrefix: "https://kovan-optimistic.etherscan.io/tx/",
    providerUrl: "https://kovan.optimism.io"
}

const npQuartz = {
    contractAddress: "0x5078dd37ee699eB59c096b41C6415417fA02Ec8c",
    ticker: "NPQ",
    name: "NuPoW Quartz",
    networkName: "Ethereum Mainnet",
    contractExplorerPrefix: "https://etherscan.io/token/",
    txExplorerPrefix: "https://etherscan.io/tx/",
    providerUrl: "https://mainnet.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npRuby = {
    contractAddress: "0xD024b2D92872394F43EE99aa0177d5a24E3A6fC2",
    ticker: "NPR",
    name: "NuPoW Ruby",
    networkName: "Optimism Mainnet",
    contractExplorerPrefix: "https://optimistic.etherscan.io/token/",
    txExplorerPrefix: "https://optimistic.etherscan.io/tx/",
    //providerUrl: "https://mainnet.optimism.io"
    providerUrl: "https://optimism-mainnet.infura.io/v3/54e1693a820e4902a71346f8167efb47"
}

const npSapphire = {
    contractAddress: "0xDdA51a4585545FBF1169393C8F39686B9838C693",
    ticker: "NPS",
    name: "NuPoW Sapphire",
    networkName: "Arbitrum Mainnet",
    contractExplorerPrefix: "https://arbiscan.io/token/",
    txExplorerPrefix: "https://arbiscan.io/tx/",
    providerUrl: "https://arb1.arbitrum.io/rpc"
}

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route exact path="/about/" element={<About />} />
              <Route exact path="/crystals/" element={<Crystals />} />
              <Route exact path="/crystals/kovan/npk1/" element={<Activity token={npKovan1} />} />
              <Route exact path="/crystals/kovan/npk2/" element={<Activity token={npKovan2} />} />
              <Route exact path="/crystals/rinkeby/npr1/" element={<Activity token={npRinkeby1} />} />
              <Route exact path="/crystals/rinkeby/npr2/" element={<Activity token={npRinkeby2} />} />
              <Route exact path="/crystals/goerli/npg1/" element={<Activity token={npGoerli1} />} />
              <Route exact path="/crystals/goerli/npg2/" element={<Activity token={npGoerli2} />} />
              <Route exact path="/crystals/ropsten/npr1/" element={<Activity token={npRopsten1} />} />
              <Route exact path="/crystals/ropsten/npr2/" element={<Activity token={npRopsten2} />} />
              <Route exact path="/crystals/arbitrum-rinkeby/npat1/" element={<Activity token={npArbitrumTest1} />} />
              <Route exact path="/crystals/arbitrum-rinkeby/npat2/" element={<Activity token={npArbitrumTest2} />} />
              <Route exact path="/crystals/optimism-kovan/npopt1/" element={<Activity token={npOptimismKovan1} />} />
              <Route exact path="/crystals/optimism-kovan/npopt2/" element={<Activity token={npOptimismKovan2} />} />
              <Route exact path="/crystals/npq/" element={<Activity token={npQuartz} />} />
              <Route exact path="/crystals/npr/" element={<Activity token={npRuby} />} />
              <Route exact path="/crystals/nps/" element={<Activity token={npSapphire} />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
