import React, {useEffect, useState} from "react";

function Crystals() {
    useEffect(() => {
        document.body.className = "page2"
    });

    const [showDeprecated, setShowDeprecated] = useState(false);

    return (
        <div>
            <div className={"header"}>
                <div className={"menuBar"}>
                    <a href={"/about/"} className={"menuLink"}>NuPoW</a>
                    <a href={"/crystals/"} className={"menuLink"}>Crystals</a>
                </div>
            </div>
            <div className={"banner"}>
                <img src="/img/2.png"/>
            </div>
            <div className={"content"}>
                <h1>
                    Crystal tokens
                </h1>
                <p>
                    While NuPoW is an algorithm for a new and improved Proof Of Work, Crystal tokens represent an
                    application of this. Each token is a self sovereign ERC-20 token, without any admin, restrictions
                    or premine. You are free to hold and use crystals as you see fit.
                </p>
                <h2>
                    Why crystals?
                </h2>
                <p>
                    For many, crystals hold special meaning, as a medium of energy, as a potential for healing.
                    Practitioners of crystal token trading believe they can <a href={"https://en.wikipedia.org/wiki/Crystal_healing"}>
                    boost low returns, prevent bad returns, release blocked returns, and transform a portfolio's alpha.</a>
                </p>
                <p>
                    Who doesn't need a bit of that in their holdings? They can also be quite nice and interesting to
                    look at.
                </p>
                <h2>
                    Crystal flash
                </h2>
                <p>
                    Native to all crystals is their flash powers, through the <a href={"https://eips.ethereum.org/EIPS/eip-3156"}>ERC-3156 flash loans extension</a>.
                    This can be used to smooth out market inefficiencies and other friction points when using crystal tokens
                    in everyday transactions.
                </p>
                <h2>
                    List of official NuPoW crystal tokens
                </h2>
                <table>
                    <thead>
                    <tr>
                        <th>Crystal</th>
                        <th>Network</th>
                        <th>Target chain length</th>
                        <th>Max supply</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><a href={"/crystals/npq/"}>NuPoW Quartz</a></td>
                        <td>Ethereum Mainnet</td>
                        <td>37</td>
                        <td>1.1579 × 10<sup>59</sup></td>
                    </tr>
                    <tr>
                        <td><a href={"/crystals/npr/"}>NuPoW Ruby</a></td>
                        <td>Optimism Mainnet</td>
                        <td>37</td>
                        <td>21000000</td>
                    </tr>
                    <tr>
                        <td><a href={"/crystals/nps/"}>NuPoW Sapphire</a></td>
                        <td>Arbitrum Mainnet</td>
                        <td>37</td>
                        <td>21000000</td>
                    </tr>
                    </tbody>
                </table>

                {
                    (() => {
                        if (showDeprecated) {
                            return (
                                <>
                                    <h2>
                                        NuPoW test crystals on deprecated test networks
                                    </h2>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Crystal</th>
                                            <th>Network</th>
                                            <th>Target chain length</th>
                                            <th>Max supply</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><a href={"/crystals/goerli/npg1/"}>Görli 1</a></td>
                                            <td>Ethereum Görli</td>
                                            <td>37</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/goerli/npg2/"}>Görli 2</a></td>
                                            <td>Ethereum Görli</td>
                                            <td>13</td>
                                            <td>21000000</td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/kovan/npk1/"}>Kovan 1</a></td>
                                            <td>Ethereum Kovan</td>
                                            <td>37</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/kovan/npk2/"}>Kovan 2</a></td>
                                            <td>Ethereum Kovan</td>
                                            <td>5</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/rinkeby/npr1/"}>Rinkeby 1</a></td>
                                            <td>Ethereum Rinkeby</td>
                                            <td>37</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/rinkeby/npr2/"}>Rinkeby 2</a></td>
                                            <td>Ethereum Rinkeby</td>
                                            <td>5</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/ropsten/npr1/"}>Ropsten 1</a></td>
                                            <td>Ethereum Ropsten</td>
                                            <td>37</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/ropsten/npr2/"}>Ropsten 2</a></td>
                                            <td>Ethereum Ropsten</td>
                                            <td>5</td>
                                            <td>1.1579 × 10<sup>59</sup></td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/arbitrum-rinkeby/npat1/"}>Arbitrum Rinkeby 1</a>
                                            </td>
                                            <td>Arbitrum Rinkeby</td>
                                            <td>37</td>
                                            <td>21000000</td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/arbitrum-rinkeby/npat2/"}>Arbitrum Rinkeby 2</a>
                                            </td>
                                            <td>Arbitrum Rinkeby</td>
                                            <td>5</td>
                                            <td>21000000</td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/optimism-kovan/npopt1/"}>Optimism Kovan 1</a></td>
                                            <td>Optimism Kovan</td>
                                            <td>37</td>
                                            <td>21000000</td>
                                        </tr>
                                        <tr>
                                            <td><a href={"/crystals/optimism-kovan/npopt2/"}>Optimism Kovan 2</a></td>
                                            <td>Optimism Kovan</td>
                                            <td>5</td>
                                            <td>21000000</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </>
                            )
                        } else {
                            return (
                                <div
                                    onClick={() => setShowDeprecated(true)}
                                    className={"deprecated"}
                                >
                                    Click to see deprecated networks
                                </div>
                            )
                        }
                    })()
                }
            </div>
            <div className={"footer"}>
                Copyright 2022 <a href={"https://cfelde.com"}>cfelde</a>, all rights reserved.
                You are responsible for your own actions.
            </div>
        </div>
    )
}

export default Crystals;